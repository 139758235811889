<template>
  <v-card class="question-card" flat outlined @click="onClick($event)">
    <div class="question-header">
      <v-img
        height="28"
        :src="question.image_url"
        :alt="question.title"
      ></v-img>
      <span class="question-type-container">{{ questionType }}</span>
      <span class="close-button" @click.stop="closeCard" v-if="closeCard">
        <inqli-icon>mdi-close</inqli-icon>
      </span>
    </div>
    <v-card-title class="question-title-container"
      ><avatar size="small" :image="author.avatar" class="author-avatar" />
      <span class="question-title-text"> {{ authorInfo }} </span>
    </v-card-title>
    <div class="question-body">
      <v-card-text class="question-details">{{
        questionDetailPreview
      }}</v-card-text>
      <v-card-actions class="question-card-footer">
        <div class="meta-data-container">
          <meta-data-item
            :value="createdAt"
            icon="mdi-calendar"
            class="metadata-item"
          />

          <v-card-actions>
            <meta-data-item :value="replies" icon="mdi-reply" />
          </v-card-actions>
          <v-card-actions>
            <meta-data-item :value="likes" icon="mdi-heart-outline" />
          </v-card-actions>
        </div>
        <div class="read-more-button">
          <text-button>read more</text-button>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>
import MetaDataItem from "../../../app/components/MetaDataItem.vue";
import InqliAvatar from "../../../app/components/avatars/Avatar.vue";
import TextButton from "../../../app/components/buttons/TextButton.vue";
import InqliIcon from "../../../app/components/icons/InqliIcon.vue";
import moment from "moment";
export default {
  name: "QuestionCard",
  components: {
    "meta-data-item": MetaDataItem,
    avatar: InqliAvatar,
    "text-button": TextButton,
    "inqli-icon": InqliIcon,
  },
  props: {
    question: Object,
    author: Object,
    metadata: Object,
    closeCard: Function,
  },
  computed: {
    questionType() {
      return this.question.type.replace("iac-", "");
    },
    authorInfo() {
      let info = this.author.display_name;
      info += this.author.org_detail ? `, ${this.author.org_detail}` : "";
      info += this.author.org ? ` at ${this.author.org}` : "";
      return info;
    },
    createdAt() {
      return moment
        .utc(this.question.created_on)
        .local()
        .format("MMM Do YYYY, h:mm A");
    },
    replies() {
      let msg = `${this.metadata.reply_count}`;
      msg += this.metadata.reply_count <= 1 ? " reply" : " replies";
      return msg;
    },
    likes() {
      let msg = `${this.metadata.liked_count}`;
      msg += this.metadata.liked_count <= 1 ? " like" : " likes";
      return msg;
    },
    questionDetailPreview() {
      const maxCharacters = 180;
      if (this.question.details.length > maxCharacters) {
        return this.question.details.slice(0, maxCharacters) + "...";
      }
      return this.question.details;
    },
  },
  methods: {
    onClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>
<style lang="scss" scoped>
.question-header {
  position: relative;
  overflow: hidden;
  .close-button {
    position: absolute;
    top: 1px;
    right: 1px;
  }
}
.question-card {
  border-radius: 16px !important;
  border-color: #777a7a !important;
  cursor: pointer;
}
.question-type-container {
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  left: 8px;
  bottom: 4px;
  background-color: $inqliNavy !important;
  color: white;
  border: 1px solid #ffffff;
  font-size: 9px;
  padding: 2px 0;
  border-radius: 4px;
  width: 72px;
}
.question-title-text {
  color: $inqliNavy;
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  line-height: 18px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
}
.question-title-container {
  padding: 3px 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.question-body {
  padding: 0 10px;
}
.question-details {
  padding: 0;
  text-align: left;
  color: $inqliNavy !important;
  font-size: 13px;
  line-height: 18px;
}
.meta-data-container {
  display: flex;
  justify-content: flex-start;
  width: 70%;
}
.metadata-item {
  margin-right: 15px;
}
.question-card-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2px 0;
}
@media (max-width: $breakpoint-mobile) {
  .read-more-button {
    margin: 5px auto;
  }
  .meta-data-container {
    width: 100%;
  }
}
</style>
