var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feed-container" }, [
    _c(
      "div",
      {
        class: {
          "feed-list": true,
          "feed-list-dense-display": _vm.isFeedDetailsShowing
        }
      },
      [_vm._t("list")],
      2
    ),
    _vm.isFeedDetailsShowing
      ? _c(
          "div",
          { staticClass: "feed-item-detail" },
          [
            !_vm.isMobileSize
              ? _c("div", { staticClass: "feed-item-side-display" }, [
                  _c("div", { staticClass: "px-5" }, [_vm._t("default")], 2)
                ])
              : _c(
                  "v-dialog",
                  {
                    attrs: {
                      persistent: "",
                      transition: "dialog-bottom-transition",
                      "max-width": "100vw",
                      "overlay-opacity": "0.95",
                      width: "100%"
                    },
                    model: {
                      value: _vm.isFeedDetailsShowing,
                      callback: function($$v) {
                        _vm.isFeedDetailsShowing = $$v
                      },
                      expression: "isFeedDetailsShowing"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "feed-detail-card-mobile px-2" },
                      [_vm._t("default")],
                      2
                    )
                  ]
                )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }