var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "question-card",
      attrs: { flat: "", outlined: "" },
      on: {
        click: function($event) {
          return _vm.onClick($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "question-header" },
        [
          _c("v-img", {
            attrs: {
              height: "28",
              src: _vm.question.image_url,
              alt: _vm.question.title
            }
          }),
          _c("span", { staticClass: "question-type-container" }, [
            _vm._v(_vm._s(_vm.questionType))
          ]),
          _vm.closeCard
            ? _c(
                "span",
                {
                  staticClass: "close-button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.closeCard.apply(null, arguments)
                    }
                  }
                },
                [_c("inqli-icon", [_vm._v("mdi-close")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "question-title-container" },
        [
          _c("avatar", {
            staticClass: "author-avatar",
            attrs: { size: "small", image: _vm.author.avatar }
          }),
          _c("span", { staticClass: "question-title-text" }, [
            _vm._v(" " + _vm._s(_vm.authorInfo) + " ")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "question-body" },
        [
          _c("v-card-text", { staticClass: "question-details" }, [
            _vm._v(_vm._s(_vm.questionDetailPreview))
          ]),
          _c("v-card-actions", { staticClass: "question-card-footer" }, [
            _c(
              "div",
              { staticClass: "meta-data-container" },
              [
                _c("meta-data-item", {
                  staticClass: "metadata-item",
                  attrs: { value: _vm.createdAt, icon: "mdi-calendar" }
                }),
                _c(
                  "v-card-actions",
                  [
                    _c("meta-data-item", {
                      attrs: { value: _vm.replies, icon: "mdi-reply" }
                    })
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c("meta-data-item", {
                      attrs: { value: _vm.likes, icon: "mdi-heart-outline" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "read-more-button" },
              [_c("text-button", [_vm._v("read more")])],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }