<template>
  <div class="feed-container">
    <div
      :class="{
        'feed-list': true,
        'feed-list-dense-display': isFeedDetailsShowing,
      }"
    >
      <slot name="list"></slot>
    </div>
    <div class="feed-item-detail" v-if="isFeedDetailsShowing">
      <div class="feed-item-side-display" v-if="!isMobileSize">
        <div class="px-5">
          <slot></slot>
        </div>
      </div>
      <v-dialog
        v-else
        v-model="isFeedDetailsShowing"
        persistent
        transition="dialog-bottom-transition"
        max-width="100vw"
        overlay-opacity="0.95"
        width="100%"
      >
        <div class="feed-detail-card-mobile px-2">
          <slot></slot>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "FeedLayout",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFeedDetailsShowing: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", this.value);
      },
    },
    isMobileSize() {
      return window.innerWidth < 1024;
    },
  },
};
</script>
<style lang="scss" scoped>
.feed-container {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  margin: 0 auto;
  flex-wrap: nowrap;
  margin-bottom: 70px;
}
.feed-list {
  padding: 0 10px;
  max-width: 800px;
  width: 60%;
}
.feed-list-dense-display {
  width: 45%;
}
feed-item-detail {
  width: 52%;
  overflow: visible;
}
.feed-item-side-display {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 100px;
  z-index: 2;
  overflow: auto;
  height: 82vh;
  width: 45vw;
  padding-top: 20px;
  padding-bottom: 15px;
}

@media (max-width: $breakpoint-tablet-large) {
  .feed-container {
    width: 100%;
    margin-bottom: 100px;
  }
  .feed-list {
    width: 100%;
    padding: 0;
  }
  .feed-item-detail {
    width: 100%;
  }
  .feed-detail-card-mobile {
    overscroll-behavior: contain;
  }
  .feed-item-side-display {
    position: fixed !important;
    top: 120px;
    left: 0;
    width: 100%;
    height: 85vh;
    padding-bottom: 10px;
  }
}
</style>
